import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('showingChat', [
      state('hidden', style({
        display: 'none'
      })),
      state('shown', style({
        display: 'block'
      })),
      transition('hiden=>shown', animate('30000ms')),
      transition('shown=>hiden', animate('30000ms'))
    ])
  ]
})
export class ChatComponent implements OnInit { 
  currentState='hidden';

  constructor() { }

  ngOnInit() {
  }

  changeState() { 
    this.currentState = this.currentState === 'hidden' ? 'shown' : 'hidden';
  }
}
