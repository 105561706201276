import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-area',
  templateUrl: './main-area.component.html',
  styleUrls: ['./main-area.component.scss']
})
export class MainAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }

  faOckhamsblog = {
    prefix: 'fa',
    iconName: 'okhamsblog',
    icon: [
       448, // width
       488, // height
       [],  //ligatures
       null, //unicode
      ''
      ]
  }

}
