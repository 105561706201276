import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component'
import { AlertService } from './alerts.service';

@NgModule({
  declarations: [
    AlertsComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [AlertService],
  exports: [AlertsComponent]
})
export class AlertsModule { }
