import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener, Input } from '@angular/core';

export const NAVBAR_HEIGHT = 48;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @Input() currentSection: number;
  
  sticky: boolean = false;
  menuPosition: any;
  @ViewChild('stickyMenu', {static: false}) 
  private menuElement: ElementRef;
  
  constructor() { }
  ngOnInit() { }

  ngAfterViewInit(){
    this.menuPosition = this.menuElement.nativeElement.offsetTop
  }

  @HostListener('window:scroll')
    handleScroll(){
      const windowScroll = window.pageYOffset;
      if(windowScroll >= this.menuPosition + 3){
          this.sticky = true;
      } else {
          this.sticky = false;
      }
    }
}
