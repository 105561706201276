import { Component, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { NAVBAR_HEIGHT } from './header/header.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'web';

  @ViewChild('banner', {static: false, read: ElementRef}) bannerElement: ElementRef;
  @ViewChild('service', {static: false, read: ElementRef}) serviceElement: ElementRef;
  @ViewChild('company', {static: false, read: ElementRef}) aboutCompanyElement: ElementRef;
  @ViewChild('about', {static: false, read: ElementRef}) aboutElement: ElementRef;
  @ViewChild('contact', {static: false, read: ElementRef}) contactElement: ElementRef;

  public currentActive: number  = 0;
  public bannerOffset: Number = null;
  public serviceOffset: Number = null;
  public aboutCompanyOffset: Number = null;
  public aboutOffset: Number = null;
  public contactOffset: Number = null;

  ngAfterViewInit(){
    this.bannerOffset = this.bannerElement.nativeElement.offsetTop;
    this.serviceOffset = this.serviceElement.nativeElement.offsetTop - NAVBAR_HEIGHT;
    this.aboutCompanyOffset = this.aboutCompanyElement.nativeElement.offsetTop - NAVBAR_HEIGHT;
    this.aboutOffset = this.aboutElement.nativeElement.offsetTop - NAVBAR_HEIGHT;
    this.contactOffset = this.contactElement.nativeElement.offsetTop - NAVBAR_HEIGHT;
  }

  @HostListener('window:scroll')
    checkOffsetTop() {
      if (window.pageYOffset >= this.bannerOffset && window.pageYOffset < this.serviceOffset) {
        this.currentActive = 1;
      } else if (window.pageYOffset >= this.serviceOffset && window.pageYOffset < this.aboutCompanyOffset) {
        this.currentActive = 2;
      } else if (window.pageYOffset >= this.aboutCompanyOffset && window.pageYOffset < this.aboutOffset) {
        this.currentActive = 3;
      } else if (window.pageYOffset >= this.aboutOffset && window.pageYOffset < this.contactOffset) {
        this.currentActive = 4;
      } else if (window.pageYOffset >= this.contactOffset){
        this.currentActive = 5;
      } else {
        this.currentActive = 0;
      }
    }
}
