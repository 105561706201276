import { Component, OnInit } from '@angular/core';
import { ContactFormClientService } from '../contact-form-client/contact-form-client.service';
import { ContactRequest } from '../contact-form-client/model/model'
import { AlertService } from '../alerts/alerts.service';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.scss']
})
export class ContactMeComponent implements OnInit {

  public errorMesssage: string = null;
  public messageSent: boolean = false;
  public consentAccepted: boolean;

  constructor(private client: ContactFormClientService,
              private alertService: AlertService) { }

  ngOnInit() {
    this.consentAccepted = false;
    this.messageSent = false;
  }

  consentChange( value: boolean) {
    this.consentAccepted = value;
    console.log(value)
  }

  onClickSubmit(formData) {
    const request = <ContactRequest> {
      sender: formData.email,
      name: formData.name,
      comment: formData.message,
    }
    this.client.sendContactForm(request)
      .subscribe( 
        resOk => {
          this.messageSent = true;
        }, 
        error => {
        this.errorMesssage = error.message
        this.alertService.error(error.message)
      });    
 }
}
