import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent, NAVBAR_HEIGHT } from './header/header.component';
import { MainAreaComponent } from './main-area/main-area.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { AboutMeComponent } from './about-me/about-me.component';
import { ContactMeComponent } from './contact-me/contact-me.component';
import { FooterComponent } from './footer/footer.component';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatComponent } from './chat/chat.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { AlertsModule } from './alerts/alerts.module';

import { ContactFormClientService } from './contact-form-client/contact-form-client.service';
import { AlertsComponent } from './alerts/alerts.component';
import { SpinnerComponent } from './spinner/spiner.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainAreaComponent,
    PortfolioComponent,
    AboutMeComponent,
    ContactMeComponent,
    FooterComponent,
    AboutCompanyComponent,
    ChatComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    NgxPageScrollCoreModule.forRoot({duration: 800, scrollOffset: NAVBAR_HEIGHT}),
    NgxPageScrollModule,
    FormsModule,
    HttpClientModule,
    AlertsModule
  ],
  providers: [ContactFormClientService],
  bootstrap: [AppComponent]
})
export class AppModule { }
