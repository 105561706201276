import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactRequest } from './model/model';

@Injectable({
  providedIn: 'root'
})
export class ContactFormClientService {

  constructor(private http: HttpClient) { }

  public sendContactForm(request: ContactRequest) {
    return this.http.post('https://api.benjaminvegadelpino.com/contact-form/', request);
  }
  
}
